import { createEnhancerSuperInstance } from '@cld/widget-iframe-creator-core';
import eventWithResponse from '../helpers/EventWithResponse';
import { MediaLibraryEvents } from '@cld/custom-action-widget-common';
import promiseResolver from '../helpers/PromiseResolver';
import { CONFIG_SIGNATURE } from '../constants/consts';

const configPromise = new Promise((resolve, reject) => {
  promiseResolver.addResolver(CONFIG_SIGNATURE, resolve, reject);
});

// Please update types in @cld/custom-actions-api when modifying api surface here
const withConsoleOperations = (fn) => (args) => {
  const child = createEnhancerSuperInstance({ fn, args });
  return {
    ...child,
    createItem: eventWithResponse(child, MediaLibraryEvents.CREATE_ITEM),
    getItems: eventWithResponse(child, MediaLibraryEvents.GET_ITEMS),
    updateItems: eventWithResponse(child, MediaLibraryEvents.UPDATE_ITEMS),
    downloadItems: eventWithResponse(child, MediaLibraryEvents.DOWNLOAD_ITEMS),
    upload: eventWithResponse(child, MediaLibraryEvents.UPLOAD),
    closeModal: () => child.emit(MediaLibraryEvents.CLOSE_MODAL),
    showToast: (message, type) => {
      child.emit(MediaLibraryEvents.TOAST, { message, type });
    },
    getConfig: () => configPromise,
  };
};

export default withConsoleOperations;
