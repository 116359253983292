import { childBuilder, withConfiguration, withOmitInternalEvents } from '@cld/widget-iframe-creator-child';
import { CustomActionEvents } from '@cld/custom-action-widget-common';
import { MediaLibraryEvents } from '@cld/custom-action-widget-common';
import promiseResolver from './helpers/PromiseResolver';
import withConsoleOperations from './child/withConsoleOperations';
import { CONFIG_SIGNATURE } from './constants/consts';

// Functions
export const onConfigUpdate = (config) => {
  promiseResolver.resolve(CONFIG_SIGNATURE, config);
  return { config };
};
export const onResponse = ({ operationId, ...data }) => operationId && promiseResolver.resolve(operationId, data);
export const onError = ({ operationId, ...error }) => operationId && promiseResolver.reject(operationId, error);

// Child instance
const child = childBuilder(
  withConfiguration,
  withOmitInternalEvents,
  withConsoleOperations
)({
  outgoingEvents: MediaLibraryEvents,
  incomingEvents: CustomActionEvents,
  onConfigUpdate,
});

child.on(CustomActionEvents.RESPONSE, onResponse);
child.on(CustomActionEvents.ERROR, onError);

child.loaded();

window.cloudinary || (window.cloudinary = {});
window.cloudinary.customAction = child;
