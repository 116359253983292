/**
 * PromiseResolver is a class that stores the resolver functions of promises, which we can deffer and resolve later.
 * An operationId is the operation's identifier.
 */
class PromiseResolver {
  constructor() {
    this.promiseResolvers = {};
  }

  /**
   * Adds a new set of resolve/reject per operation id.
   * @param operationId {string} Id of the operation
   * @param resolve {function} Promise resolve function
   * @param reject {function} Promise reject function
   * @return void.
   */
  addResolver(operationId, resolve, reject) {
    this.promiseResolvers[operationId] = {
      resolve,
      reject,
    };
  }

  /**
   * Resolves the promise of the operation with data from the parent.
   * @param operationId {string} Id of the operation
   * @param data {Object | string} Result data of the operation
   * @return void.
   */
  resolve(operationId, data) {
    this.promiseResolvers[operationId]?.resolve(data);
    this._cleanResolver(operationId);
  }

  /**
   * Rejects the promise of the operation with data from the parent.
   * @param operationId {string} Id of the operation
   * @param error {Object | string} Error of the operation
   * @return void.
   */
  reject(operationId, error) {
    this.promiseResolvers[operationId]?.reject(error);
    this._cleanResolver(operationId);
  }

  /**
   * Removes the resolver from memory
   * @param operationId {string} Id of the operation
   * @return void.
   */
  _cleanResolver(operationId) {
    delete this.promiseResolvers[operationId];
  }
}

const promiseResolver = new PromiseResolver();
export default promiseResolver;
