import promiseResolver from './PromiseResolver';
import { generateSignature } from './SignatureGenerator';

/**
 * @param child - Widget Iframe Creator Child
 * @param event {string} - Event name
 * @returns {function(*): Promise<unknown>}
 *
 * This function is used to create an operation for which we want to get the result from the parent (ML).
 * After emitting the event, it returns a promise which is resolved once the parent returns a response.
 * The operationId is a way to distinguish one request from the other.
 */
const eventWithResponse = (child, event) => (data) => {
  const newOperationId = generateSignature();
  child.emit(event, { ...data, operationId: newOperationId });
  return new Promise((resolve, reject) => {
    promiseResolver.addResolver(newOperationId, resolve, reject);
  });
};

export default eventWithResponse;
